export function header() {
  $(function(){
    $('.j-header_btn').on('click', function() {
      $('.l-header').toggleClass('active');
    });
    $('.j-header_link').on('click', function() {
      $('.l-header').toggleClass('active');
    });
  });

  $( function(){
    // モーダル領域をクリックでフェードアウトさせる
    $( '.l-header_nav' ).click( function(){
      $('.l-header').toggleClass('active');
    } );
    // がしかし、画像をクリックでキャンセルさせる
    $( '.l-header_nav_item' ).on( 'click', function( e ){
      e.stopPropagation();
    } );
  } );
}
